<template>
  <section class="content-page pl-0 pr-0">
    <div class="PlanToBuy">
      <!-- <div class="back_alert d-flex"> -->
      <!-- 返回 -->
      <el-row>
        <el-col :xs="24" :sm="6">
          <el-button
            icon="el-icon-arrow-left"
            style="color: #50b5ff"
            class="mb-2 mr-2"
            @click="$router.back()"
            >{{ $t("Events.024@back") }}
          </el-button>
        </el-col>
        <!-- 判断是否注册 -->
        <el-col :xs="24" :sm="18">
          <el-alert
            v-show="!is_register"
            style="color: #ffba68"
            show-icon
            center
            class="mb-2"
            :title="$t('Events.ea1@you_have_not_registe')"
            type="warning"
          >
          </el-alert>
        </el-col>
      </el-row>
      <!-- </div> -->

      <!-- <div class="single_program">
        <div class="program_title">
          <ul class="d-flex p-0 m-0">
            <li>{{ $t("Events.c0f@date") }}</li>
            <li>{{ $t("Events.4eb@program") }}</li>
            <li>{{ $t("Events.db9@price") }}</li>
            <li>{{ $t("Events.9da@day_ticket") }}</li>
            <li>{{ $t("Events.338@all_pass") }}</li>
            <li>{{ $t("Events.da0@online_pass") }}</li>
          </ul>
        </div>

        <div class="program_main_box">
          <table style="width: 100%" class="buy-table my_table">
            <tr v-for="(value, key, index) in planData" :key="index">
              <td class="pl-3" v-if="key != 'test'" style="width: 12.5%">
                {{ key }}
              </td>
              <td>
                <div v-for="(item, index) in value.single_ticket" :key="index">
                  <div class="p-2">
                    <p class="m-0 time_p">
                      {{ item.time ? item.time : "-" }}<br />
                      <span class="time_zone">
                        {{ item.time_zone ? item.time_zone : "-" }}
                      </span>
                    </p>
                    <div class="program-table-content">
                      <div class="program_main" @click="toProgramDetail(item)">
                        <i
                          v-if="item.is_cron"
                          :style="{
                            background:
                              item.model_type == 4
                                ? '#3A67B1'
                                : item.model_type == 3
                                ? '#0097D7'
                                : item.model_type == 2
                                ? '#6FC284'
                                : item.model_type == 1
                                ? '#53C5B8'
                                : '#916CAF',
                          }"
                        ></i>
                        <p
                          :class="[
                            'm-0',
                            {
                              'cursor-pointer':
                                item.model_type &&
                                item.model_id &&
                                item.is_cron,
                            },
                          ]"
                        >
                          {{ item.program_title }}
                        </p>
                        <span
                          v-if="item.is_cron"
                          :class="{
                            MeetingTag: item.model_type == 4,
                            EventTag: item.model_type == 2,
                            courseTag: item.model_type == 3,
                            projectTag: item.model_type == 1,
                            noneTag: item.model_type == 0,
                          }"
                          class="tag"
                        >
                          {{ judgeCurrentType(item) }}
                        </span>
                      </div>
                      <span
                        style="font-size: 12px"
                        class="m-0"
                        v-if="
                          new Date().getTime() <
                          new Date(item.bird_end_time).getTime()
                        "
                        >{{ $t("Events.5df@early_bird_time") }}:
                        {{ item.bird_time }}</span
                      >
                    </div>
                  </div>
                </div>
              </td>
              <td v-if="key != 'test'">
                <div
                  v-for="(item, index) in value.single_ticket"
                  :key="index"
                  :style="{ background: item.isChecked ? '#FAFAFB' : '#fff' }"
                  class="isBuy_relative"
                >
                  <div
                    class="check-relative d-flex justify-content-center"
                    @click="checkSinglePrice(item)"
                    v-show="item.is_edit"
                  >
                    <div
                      class="bird_price_box"
                      style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      <p v-show="item.is_show_bird_price" class="m-0">
                        {{ item.bird_price | priceFilter }}
                      </p>

                      <p
                        :style="{
                          'text-decoration': item.is_show_bird_price
                            ? 'line-through'
                            : 'none',
                        }"
                        class="m-0 informationIcon"
                      >
                        {{ item.price | priceFilter }}
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="`Birld Time:${item.bird_time}`"
                          placement="top-start"
                        >
                          <i
                            v-if="item.is_show_bird_price"
                            class="ri-information-line"
                          ></i>
                        </el-tooltip>
                      </p>
                    </div>
                    <i
                      v-show="item.isChecked"
                      class="ri-check-line check_self_icon"
                    ></i>
                  </div>
                  <span class="Purchased" v-show="item.is_buy">{{
                    $t("Events.464@purchased")
                  }}</span>
                </div>
              </td>

              <td v-if="key != 'test'" class="day_ticket_td">
                <div
                  :style="{
                    background: value.day_ticket.isDayChecked
                      ? '#FAFAFB'
                      : '#fff',
                  }"
                  v-show="value.day_ticket.is_edit"
                  class="test_div"
                  @click="checkDayPrice(value.day_ticket)"
                >
                  <p v-show="value.day_ticket.is_show_bird_price" class="m-0">
                    {{ value.day_ticket.bird_price | priceFilter }}
                  </p>
                  <p
                    :style="{
                      'text-decoration': value.day_ticket.is_show_bird_price
                        ? 'line-through'
                        : 'none',
                    }"
                    class="m-0 informationIcon"
                  >
                    {{ value.day_ticket.price | priceFilter }}
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="`Birld Time:${value.day_ticket.bird_time}`"
                      placement="top-start"
                    >
                      <i
                        v-if="value.day_ticket.is_show_bird_price"
                        class="ri-information-line"
                        style="right: 10px"
                      ></i>
                    </el-tooltip>
                  </p>

                  <i
                    v-show="value.day_ticket.isDayChecked"
                    class="ri-check-line check_self_icon"
                  ></i>
                </div>
                <span class="Purchased" v-show="value.day_ticket.is_buy">
                  {{ $t("Events.464@purchased") }}
                </span>
              </td>

              <td
                v-if="!index"
                class="full_pass"
                :rowspan="Object.keys(planData).length"
                :class="[
                  'collapse-tr',
                  'button_fixed',
                  {
                    button_fixed_spec:
                      checkedList.length || checkedPackageList.length,
                  },
                  'button_fixed_one',
                ]"
              >
                <div
                  class="button_fixed collapse-tr"
                  v-show="full_ticket.is_edit"
                >
                  <div class="price-show">
                    <span v-show="full_ticket.is_show_bird_price"
                      >${{ full_ticket.bird_price }}</span
                    >

                    <span
                      :style="{
                        'text-decoration': full_ticket.is_show_bird_price
                          ? 'line-through'
                          : 'none',
                      }"
                      class="informationIcon"
                      >${{ full_ticket.price }}
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="`Birld Time:${full_ticket.bird_time}`"
                        placement="top-start"
                      >
                        <i
                          v-if="full_ticket.is_show_bird_price"
                          class="ri-information-line"
                        ></i>
                      </el-tooltip>
                    </span>
                  </div>
                  <el-button
                    class="px-1"
                    type="warning"
                    size="small"
                    :disabled="
                      !(full_ticket.is_edit && !full_ticket.is_buy) ||
                      !is_register
                    "
                    @click="
                      payOnlineAllPass(full_ticket.setmeal_id);
                      payConf(1, 'pass');
                    "
                    >Check out</el-button
                  >
                </div>
              </td>

              <td
                class="online_pass"
                v-if="!index"
                :rowspan="Object.keys(planData).length"
                :class="[
                  'collapse-tr',
                  'button_fixed',
                  {
                    button_fixed_spec:
                      checkedList.length || checkedPackageList.length,
                  },
                ]"
              >
                <div
                  class="button_fixed collapse-tr"
                  v-show="online_ticket.is_edit"
                >
                  <div class="price-show">
                    <span v-show="online_ticket.is_show_bird_price"
                      >${{ online_ticket.bird_price }}</span
                    >

                    <span
                      :style="{
                        'text-decoration': online_ticket.is_show_bird_price
                          ? 'line-through'
                          : 'none',
                      }"
                      class="informationIcon"
                      >${{ online_ticket.price }}
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="`Birld Time:${online_ticket.bird_time}`"
                        placement="top-start"
                      >
                        <i
                          v-if="online_ticket.is_show_bird_price"
                          class="ri-information-line"
                        ></i>
                      </el-tooltip>
                    </span>
                  </div>
                  <el-button
                    :disabled="
                      !(online_ticket.is_edit && !online_ticket.is_buy) ||
                      !is_register
                    "
                    @click="
                      payOnlineAllPass(online_ticket.setmeal_id);
                      payConf(1, 'pass');
                    "
                    type="warning"
                    size="small"
                  >
                    Check out
                  </el-button>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div
          v-if="checkedList.length || checkedPackageList.length"
          class="program_bottom"
        >
          <ul class="d-flex p-0 m-0">
            <li></li>
            <li></li>
            <li>
              <div
                v-if="checkedList.length && is_register"
                class="d-flex flex-column p-2"
              >
                <span style="text-align: center">{{ priceTotal }}</span>
                <el-button
                  @click="payConf(0)"
                  type="primary"
                  style="height: 35px"
                  class="d-flex justify-content-center align-items-center my-2"
                  >Check out
                </el-button>
              </div>
            </li>
            <li>
              <div
                v-if="checkedPackageList.length && is_register"
                class="d-flex flex-column p-2"
              >
                <span style="text-align: center">{{ DayPriceTotal }}</span>
                <el-button
                  @click="payConf(1, 'day')"
                  type="primary"
                  style="height: 35px"
                  class="d-flex justify-content-center align-items-center my-2"
                  >Check out</el-button
                >
              </div>
            </li>
            <li style="border-top: none; border-right: 1px solid #f1f1f1"></li>
          </ul>
        </div>
      </div> -->

      <!-- 打包行程 -->
      <div class="package_table_box" v-if="combination.length">
        <!-- <table style="width: 100%" class="package_table">
          <thead>
            <tr>
              <td>{{ $t("Events.a65@package") }}</td>
              <td>{{ $t("Events.7e1@program") }}</td>
              <td>{{ $t("Events.db9@price") }}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, index) in combination" :key="index">
              <td style="width: 10%; background: #fff">
                {{ value.setmeal_title }}
              </td>
              <td style="background: #fff">
                <div
                  v-for="(item, index) in value.program"
                  :key="index"
                  class="one_program"
                >
                  <div class="pt-3 pl-3 time_title_box">
                    <div class="m-0 time_box" @click="toProgramDetail(item)">
                      {{ item.program_date }} {{ item.time }}<br />
                      <span
                        style="
                          display: block;
                          width: 100%;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        {{ item.time_zone ? item.time_zone : "-" }}
                      </span>
                    </div>

                    <div class="package_title" @click="toProgramDetail(item)">
                      <i
                        v-if="item.is_cron"
                        :style="{
                          background:
                            item.model_type == 4
                              ? '#3A67B1'
                              : item.model_type == 3
                              ? '#0097D7'
                              : item.model_type == 2
                              ? '#6FC284'
                              : item.model_type == 1
                              ? '#53C5B8'
                              : '#916CAF',
                        }"
                      ></i>
                      <p
                        :class="[
                          'm-0',
                          {
                            'cursor-pointer':
                              item.model_type && item.model_id && item.is_cron,
                          },
                        ]"
                      >
                        {{ item.program_title }}
                      </p>
                      <span
                        v-if="item.is_cron"
                        :class="{
                          MeetingTag: item.model_type == 4,
                          EventTag: item.model_type == 2,
                          courseTag: item.model_type == 3,
                          projectTag: item.model_type == 1,
                          noneTag: item.model_type == 0,
                        }"
                        class="tag"
                        >{{ judgeCurrentType(item) }}</span
                      >
                    </div>
                  </div>
                </div>
              </td>
              <td style="background: #fff">
                <div class="package_btn">
                  <div class="price-show d-flex flex-column">
                    <span v-show="value.is_show_bird_price"
                      >${{ value.bird_price }}</span
                    >
                    <span
                      :style="{
                        'text-decoration': value.is_show_bird_price
                          ? 'line-through'
                          : 'none',
                      }"
                      class="informationIcon"
                      >${{ value.price }}
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="`Birld Time:${value.bird_time}`"
                        placement="top-start"
                      >
                        <i
                          v-if="value.is_show_bird_price"
                          class="ri-information-line"
                          style="color: #50b5ff; text-decoration: none"
                        ></i>
                      </el-tooltip>
                    </span>
                  </div>
                  <el-button
                    :disabled="value.is_buy || !is_register"
                    :style="{ background: value.is_buy ? '#ccc' : '#ffba68' }"
                    @click="
                      changePackageList(value);
                      payConf(1, 'package');
                    "
                    style="border: none; width: 100px"
                    type="warning"
                    size="small"
                  >
                    Check out
                  </el-button>

                </div>
              </td>
            </tr>
          </tbody>
        </table> -->
        <!-- 早鸟价格倒计时 -->
        <!-- <p v-if="value.isShowCountDown" class="time_countdown">
                    Early birld Price Countdown:<br />
                    <span style="color: #fe1108">
                      {{ filtersCountdown(value) }}
                    </span>
                  </p> -->

        <el-table style="width: 100%" border :data="combination">
          <el-table-column
            :label="$t('Events.a65@package')"
            prop="setmeal_title"
            width="170"
            align="center"
          ></el-table-column>
          <el-table-column :label="$t('Events.7e1@program')" min-width="520">
            <template slot-scope="scope">
              <div
                v-for="(item, index) in scope.row.program"
                :key="index"
                class="one_program"
              >
                <div class="pt-3 pl-3 time_title_box">
                  <div class="m-0 time_box" @click="toProgramDetail(item)">
                    {{ item.program_date }} {{ item.time }}<br />
                    <span
                      style="
                        display: block;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      "
                    >
                      {{ item.time_zone ? item.time_zone : "-" }}
                    </span>
                  </div>

                  <div class="package_title" @click="toProgramDetail(item)">
                    <i
                      v-if="item.is_cron"
                      :style="{
                        background:
                          item.model_type == 4
                            ? '#3A67B1'
                            : item.model_type == 3
                            ? '#0097D7'
                            : item.model_type == 2
                            ? '#6FC284'
                            : item.model_type == 1
                            ? '#53C5B8'
                            : '#916CAF',
                      }"
                    ></i>
                    <p
                      :class="[
                        'm-0',
                        {
                          'cursor-pointer':
                            item.model_type && item.model_id && item.is_cron,
                        },
                      ]"
                    >
                      {{ item.program_title }}
                    </p>
                    <span
                      v-if="item.is_cron"
                      :class="{
                        MeetingTag: item.model_type == 4,
                        EventTag: item.model_type == 2,
                        courseTag: item.model_type == 3,
                        projectTag: item.model_type == 1,
                        noneTag: item.model_type == 0,
                      }"
                      class="tag"
                      >{{ judgeCurrentType(item) }}</span
                    >
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            :label="$t('Events.db9@price')"
            align="center"
            width="170"
          >
            <template slot-scope="scope">
              <div class="package_btn">
                <div class="price-show d-flex flex-column">
                  <!-- 早鸟价格 -->
                  <span v-show="scope.row.is_show_bird_price"
                    >${{ scope.row.bird_price }}</span
                  >
                  <!-- 正常价格 -->
                  <span
                    :style="{
                      'text-decoration': scope.row.is_show_bird_price
                        ? 'line-through'
                        : 'none',
                    }"
                    class="informationIcon"
                    >${{ scope.row.price }}
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="`Birld Time:${scope.row.bird_time}`"
                      placement="top-start"
                    >
                      <i
                        v-if="scope.row.is_show_bird_price"
                        class="ri-information-line"
                        style="color: #50b5ff; text-decoration: none"
                      ></i>
                    </el-tooltip>
                  </span>
                </div>
                <el-button
                  :disabled="scope.row.is_buy || !is_register"
                  :style="{ background: scope.row.is_buy ? '#ccc' : '#ffba68' }"
                  @click="
                    changePackageList(scope.row);
                    payConf(1, 'package');
                  "
                  style="border: none; width: 100px"
                  type="warning"
                  size="small"
                >
                  Check out
                </el-button>

                <!-- 早鸟价格倒计时 -->
                <!-- <p v-if="value.isShowCountDown" class="time_countdown">
                    Early birld Price Countdown:<br />
                    <span style="color: #fe1108">
                      {{ filtersCountdown(value) }}
                    </span>
                  </p> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 弹框 -->
      <b-modal id="payConfirm" hide-footer>
        <div>{{ refund_intro }}</div>
        <div
          style="
            border-top: 1px solid #eee;
            margin-top: 20px;
            padding-top: 10px;
          "
        >
          <el-button
            style="float: right; margin-left: 10px"
            type="primary"
            @click="paySingle(type)"
          >
            Check out
          </el-button>
          <el-button style="float: right" type="info" @click="cancelPay">{{
            $t("Events.54c@cancel")
          }}</el-button>
        </div>
      </b-modal>
    </div>
  </section>
</template>

<script>
// 该页面已废弃
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      timeId: 0,
      allPassBirldTime: "",
      type: "",
      checkType: "",
    };
  },

  components: {},
  computed: {
    ...mapState({
      // 单一or套餐
      package_type: (state) => state.Process.package_type,
      // 是否注册
      is_register: (state) => state.Process.is_register,
      // 单一行程
      planData: (state) => state.Process.single_program,
      // full_ticket
      full_ticket: (state) => state.Process.full_package,
      // online_ticket
      online_ticket: (state) => state.Process.online_ticket,
      // combine
      combination: (state) => state.Process.package_program,

      // 支付弹框设置
      refund_intro: (state) => state.Process.payModalContent,

      // 单一价格勾选
      checkedList: (state) => state.Process.checkedList,

      // 当天价格勾选
      checkedPackageList: (state) => state.Process.checkedPackageList,

      // 勾选的allPass或online
      checkAllPassList: (state) => state.Process.checkAllPassList,
      // 购买套餐
      buyPackageList: (state) => state.Process.buyPackageList,
    }),
    priceTotal() {
      let data = this.planData;
      let total = 0;
      Object.keys(data).forEach((key) => {
        if (key != "test") {
          data[key]["single_ticket"].forEach((v, i) => {
            if (v.isChecked) {
              total += parseInt(v.is_show_bird_price ? v.bird_price : v.price);
            }
          });
        }
      });
      return `$${total}`;
    },

    DayPriceTotal() {
      let data = this.planData;
      let total = 0;
      Object.keys(data).forEach((key) => {
        if (key != "test") {
          let v = data[key]["day_ticket"];
          if (v.isDayChecked) {
            total += parseInt(v.is_show_bird_price ? v.bird_price : v.price);
          }
        }
      });
      return `$${total}`;
    },

    // filtersCountdown () {
    //   return (value) => {
    //     if (value.isShowCountDown) {
    //       clearInterval(value.countdown);
    //       value.countdown = setInterval(() => {
    //         var date = new Date();
    //         var now = date.getTime();
    //         var end = new Date(value.bird_end_time).getTime();
    //         var leftTime = end - now;
    //         console.log(leftTime);
    //         var d, h, m, s;
    //         if (leftTime >= 0) {
    //           d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
    //           h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
    //           m = Math.floor((leftTime / 1000 / 60) % 60);
    //           s = Math.floor((leftTime / 1000) % 60);
    //         } else {
    //           d = h = m = s = "00";
    //         }

    //         console.log(`${String(d).padStart(2, 0)}Day ${String(h).padStart(
    //           2,
    //           0
    //         )}:${String(m).padStart(2, 0)}:${String(s).padStart(2, 0)}`);

    //         return `${String(d).padStart(2, 0)}Day ${String(h).padStart(
    //           2,
    //           0
    //         )}:${String(m).padStart(2, 0)}:${String(s).padStart(2, 0)}`;
    //       }, 1000);
    //     }
    //   }

    // }
  },
  created() {
    this.getPackageList();
    // this.allPassBirldTimeFunc();
    // console.log("combination", this.combination);
  },

  filters: {
    priceFilter(val) {
      return Number(val) ? `$${val}` : "Free";
    },
    jumpModelFilter(val) {
      // console.log("cal", val);
      return val == 1
        ? "Project"
        : val == 2
        ? "Event"
        : val == 3
        ? "Course"
        : val == 4
        ? "Meeting"
        : "";
    },
  },

  methods: {
    ...mapActions(["_packageList"]),
    ...mapMutations([
      "checkSinglePrice",
      "checkDayPrice",
      "cancelPay",
      "payOnlineAllPass",
      "clearCheckArray",
      "changePackageList",
    ]),
    judgeCurrentType(item) {
      let type = item.model_type;
      return type == 1
        ? "Project"
        : type == 2
        ? "Event"
        : type == 3
        ? "Course"
        : type == 4
        ? "Meeting"
        : "";
    },
    payConf(type, checkType) {
      this.$bvModal.show("payConfirm");
      this.type = type;
      this.checkType = checkType;
    },

    getPackageList() {
      let type = this.$route.query.type;
      let typeId = {
        1: "project_id",
        2: "event_id",
        3: "course_id",
        4: "meeting_id",
      };
      let ajaxType = {
        1: "packageList",
        2: "eventPackageList",
        3: "coursePackageList",
        4: "meetingPackageList",
      };
      this._packageList([
        {
          [typeId[type]]: this.$route.query.id,
          time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        ajaxType[type],
      ]);
    },

    // allPassBirldTimeFunc () {
    //   clearInterval(this.timeId);
    //   this.timeId = setInterval(() => {
    //     this.allPassBirldTime = this._autoProcessTime(
    //       this.full_ticket.bird_end_time
    //     );
    //   }, 1000);
    // },

    paySingle(type) {
      let checkType = this.checkType;
      let modelType = this.$route.query.type;
      let typeId = {
        1: "project_id",
        2: "event_id",
        3: "course_id",
        4: "meeting_id",
      };
      let ajaxType = {
        1: "payPackage",
        2: "eventPayPackage",
        3: "coursePayPackage",
        4: "meetingPayPackage",
      };
      let setmeal = {
        day: this.checkedPackageList,
        pass: this.checkAllPassList,
        package: this.buyPackageList,
      };
      this.$http[ajaxType[modelType]]({
        [typeId[modelType]]: this.$route.query.id,
        order_type: type,
        program: this.checkedList,
        setmeal: setmeal[checkType],
        time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }).then((res) => {
        // if (res.status == 200) {
        //   // this._packageList();
        //   this.getPackageList();
        //   this.clearCheckArray();
        //   if (res.data.is_jump == 1) {
        //     this.$router.push({
        //       path: `/PayMoney`,
        //       query: {
        //         type: modelType,
        //         id: res.data.order_id,
        //       },
        //     });
        //   } else if (res.data.is_jump == 0) {
        //     this.$router.push({
        //       path: `/PaySuccess`,
        //       query: {
        //         type: modelType,
        //         typeStatus: 1,
        //       },
        //     });
        //   }
        // }
      });
      this.$bvModal.hide("payConfirm");
    },

    toProgramDetail(item) {
      let path = {
        1: "/project-detail",
        2: "/event-detail",
        3: "/course-detail",
        4: "/meeting-details",
      };
      if (item.model_type && item.model_id && item.is_cron) {
        this.$router.push({
          path: path[item.model_type],
          query: {
            id: item.model_id,
            type: item.model_type,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" src="./scss/plantobuyPackage.scss"></style>
<style lang="scss" scoped>
::v-deep .el-table thead tr th {
  height: 50px;
  background-color: #f5fbff;
  font-family: "poppins", "montserra";
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #50b5ff;
  line-height: 50px;
}
::v-deep .el-table thead tr th:nth-last-child(1) {
  text-align: left;
}
::v-deep .el-table thead tr th :first-child {
  text-align: left;
}
.PlanToBuy {
  position: relative;

  .back_alert {
    width: 1200px;
    margin-bottom: 20px;
  }
  .one_program {
    border-bottom: 1px solid #f1f1f1;

    &:last-child {
      border-bottom: none;
    }

    .time_title_box {
      display: flex;
      height: 100px;
      > div.time_box {
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > .package_title {
        width: calc(100% - 250px);
        margin-left: 50px;
        box-sizing: border-box;
        position: relative;

        //彩色小圆点
        > i {
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          position: absolute;
          top: 7px;
          left: -30px;
        }
        // 标题
        > p {
          width: 70%;
          position: absolute;
          top: 0;
          left: 0;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        // tag
        > span.tag {
          padding: 0 10px;
          margin-left: 5px;
          position: absolute;
          top: 0;
          right: 5%;

          &.MeetingTag {
            background-color: #ecf0f7;
            color: #3a67b1;
          }

          &.EventTag {
            background-color: #f0f9f2;
            color: #6fc284;
          }

          &.courseTag {
            background-color: #e6f4fb;
            color: #0097d7;
          }

          &.projectTag {
            background-color: #eef9f8;
            color: #53c5b8;
          }

          &.noneTag {
            display: none;
          }
        }
      }
    }
  }
  // .single_program {
  //   background: #fff5f4;
  //   position: relative;

  //   .program_title {
  //     width: 1200px;

  //     > ul > li {
  //       border: 1px solid #f1f1f1;
  //       // width: 10%;
  //       width: 12.5%;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //       white-space: nowrap;
  //       line-height: 50px;
  //       background-color: #f5fbff;
  //       color: #50b5ff;
  //       padding-left: 10px;

  //       &:nth-child(2) {
  //         width: 50%;
  //       }
  //     }
  //   }

  //   .program_main_box {
  //     width: 1200px;
  //     max-height: 650px;
  //     overflow: scroll;
  //     background: #fff;
  //     scrollbar-width: none;

  //     &::-webkit-scrollbar {
  //       display: none;
  //     }

  //     tr {
  //       td {
  //         border: 1px solid #f1f1f1;
  //         // width: 10%;
  //         width: 12.5%;

  //         &:nth-child(2) {
  //           text-align: left;
  //           width: 50%;
  //         }

  //         // 每一个单独行程的样式
  //         &:nth-child(2),
  //         &:nth-child(3) {
  //           > div {
  //             border-bottom: 1px solid #f1f1f1;

  //             &:last-child {
  //               border: none;
  //             }

  //             > div {
  //               display: flex;
  //               height: 100px;

  //               .time_p {
  //                 width: 30%;

  //                 > .time_zone {
  //                   display: block;
  //                   width: 100%;
  //                   overflow: hidden;
  //                   text-overflow: ellipsis;
  //                   white-space: nowrap;
  //                 }
  //               }

  //               .program-table-content {
  //                 width: calc(70% - 1.5625rem);
  //                 margin-left: 50px;
  //                 box-sizing: border-box;

  //                 > div.program_main {
  //                   width: 100%;
  //                   height: 28px;
  //                   position: relative;
  //                   color: #333;
  //                   font-size: 16px;

  //                   > i {
  //                     display: block;
  //                     width: 12px;
  //                     height: 12px;
  //                     border-radius: 50%;
  //                     position: absolute;
  //                     top: 7px;
  //                     left: -30px;
  //                   }

  //                   p {
  //                     // max-width: 150px;
  //                     width: calc(100% - 65px);
  //                     position: absolute;
  //                     top: 0;
  //                     left: 0;
  //                     white-space: nowrap;
  //                     overflow: hidden;
  //                     text-overflow: ellipsis;
  //                   }

  //                   .tag {
  //                     padding: 0 10px;
  //                     margin-left: 5px;
  //                     position: absolute;
  //                     top: 0;
  //                     right: 0;
  //                   }

  //                   .MeetingTag {
  //                     background-color: #ecf0f7;
  //                     color: #3a67b1;
  //                   }

  //                   .EventTag {
  //                     background-color: #f0f9f2;
  //                     color: #6fc284;
  //                   }

  //                   .courseTag {
  //                     background-color: #e6f4fb;
  //                     color: #0097d7;
  //                   }

  //                   .projectTag {
  //                     background-color: #eef9f8;
  //                     color: #53c5b8;
  //                   }

  //                   .noneTag {
  //                     display: none;
  //                   }
  //                 }

  //                 > span {
  //                   color: #666666;
  //                 }

  //                 > span {
  //                   color: #666666;
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }

  //     // price
  //     .isBuy_relative {
  //       position: relative;

  //       > .check-relative {
  //         position: relative;
  //         cursor: pointer;

  //         > div.bird_price_box {
  //           position: absolute;
  //           top: 20%;
  //           left: center;
  //         }

  //         i.check_self_icon {
  //           position: absolute;
  //           bottom: 5px;
  //           right: 5px;
  //           font-size: 18px;
  //           color: #50b5ff;
  //         }
  //       }

  //       > span.Purchased {
  //         position: absolute;
  //         display: block;
  //         width: 100%;
  //         bottom: 0;
  //         left: 0;
  //         background: #fff5f4;
  //         color: #ff9b8a;
  //         font-size: 12px;
  //         text-align: center;
  //       }
  //     }

  //     // 全天票
  //     .day_ticket_td {
  //       position: relative;

  //       .test_div {
  //         position: absolute;
  //         top: 0;
  //         left: 0;
  //         width: 100%;
  //         height: 100%;
  //         padding-top: 20%;

  //         > p {
  //           color: #999;
  //           text-align: center;

  //           > span {
  //             color: #ef5c5c;
  //           }
  //         }

  //         > i.check_self_icon {
  //           position: absolute;
  //           bottom: 5px;
  //           right: 5px;
  //           font-size: 18px;
  //           color: #50b5ff;
  //         }
  //       }

  //       .Purchased {
  //         position: absolute;
  //         display: block;
  //         width: 100%;
  //         bottom: 0;
  //         left: 0;
  //         background: #fff5f4;
  //         color: #ff9b8a;
  //         font-size: 12px;
  //         text-align: center;
  //       }
  //     }

  //     // full-pass
  //     .full_pass,
  //     .online_pass {
  //       position: relative;
  //       .button_fixed {
  //         position: absolute;
  //         top: 50%;
  //         right: 50%;
  //         transform: translate(50%, -50%);
  //       }
  //       // online-package、allackage
  //       .collapse-tr {
  //         padding: 10px 0;
  //         display: flex;
  //         flex-direction: column;
  //         justify-content: center;
  //         align-items: center;

  //         > .price-show {
  //           height: auto;

  //           > span {
  //             display: block;
  //             text-align: center;

  //             &:first-child {
  //               color: #666;
  //             }

  //             &:last-child {
  //               color: #333;
  //               // font-size: 18px;
  //             }
  //           }
  //         }

  //         > .el-button {
  //           width: 90px;
  //           background: #ffba68;
  //           border: none;
  //           overflow: hidden;
  //           text-overflow: ellipsis;
  //           white-space: nowrap;
  //         }
  //       }
  //     }
  //   }

  //   .program_bottom {
  //     width: 1200px;
  //     background: #fff;

  //     > ul > li {
  //       // width: 10%;
  //       width: 12.5%;
  //       border-right: 1px solid #f1f1f1;
  //       border-top: 1px solid #f1f1f1;

  //       &:nth-child(2) {
  //         width: 50%;
  //       }
  //     }
  //   }
  // }
}
</style>
